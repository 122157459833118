<template lang="pug">
  .Principal
    .panel
      slot
</template>

<script>
export default {
  name: "Panel"
};
</script>

<style>
.Principal {
  max-width: 450px;
  display: block;
  margin: auto;
  margin-top: 10px;
}

.panel {
  /*box-shadow: 0px 0px 2px 1px #e2e2e2;*/
  border-radius: 5px;
  background: #00599d;
  /* color: black !important; */
  display: grid;
  font-weight: bold;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 447px;
  margin: auto;
}

.item-panel {
  padding: 1rem;
  border: 2.5px solid #ffffff;
  border-left: 2.5px solid white;
  cursor: pointer;
  text-align: center;
  border-right: 2.5px solid white;
  color: white;
}

.item-panel.bigbutton {
  font-size: 1.3em !important;
  padding: 0.4rem 1rem;
}

.item-panel:last-of-type {
  background-color: #00599d;
  color: white;
}
/* 
.item-panel:last-of-type:hover {
  background-color: #014579;
} */
</style>
