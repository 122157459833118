<template lang="pug">
  div
    .Progress(style="position: fixed;")
      .container.p-5
        .progress(:data-percentage="percent" @click="toHome" style="cursor: pointer;")
          span.progress-left
            span.progress-bar
          span.progress-right
            span.progress-bar
          .progress-value
            div(:style="colorComputed")
              span
                Home(:size="25").colorHouse
        p(style="position: fixed; top: 57px; left: 8px;") Inicio
</template>

<script>
/* eslint-disable prettier/prettier */
import Home from "mdi-vue/Home";
import { mapState, mapActions } from "vuex";

export default {
  name: "Progress",
  data() {
    return {
      percent: 100,
      seconds: 15,
      timeout: null
    };
  },
  components: {
    Home
  },
  created() {
    if (this.restart) {
      this.$store.dispatch("setTimer");
    }
    this.seconds = 15;
    this.timerFunction();
  },
  mounted() {
    document.querySelector(".progress-right .progress-bar").style = this.colorComputed;
    document.querySelector(".progress-left .progress-bar").style = this.colorComputed;
    document.querySelector(".colorHouse").style = this.colorComputed;
  },
  beforeDestroy() {
    clearInterval(this.timeout);
  },
  computed: {
    ...mapState({
      generalTimeout: state => state.generalTimeout,
      view: state => state.virtualrow.view,
      restart: state => state.restart,
      removeTimeout: state => state.removeTimeout,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor
    }),

    colorComputed() {
        return `color: ${this.primaryColorBlue}; border-color: ${this.primaryColorBlue};`;
    },
  },
  watch: {
    generalTimeout() {
      if (this.removeTimeout) return this.percent = 0;
      this.percent = 0;
      setTimeout(() => {
        this.seconds = 15;
        this.percent = 100;
      }, 100);
    },
    primaryColorBlue() {
      document.querySelector(".progress-right .progress-bar").style = this.colorComputed;
      document.querySelector(".progress-left .progress-bar").style = this.colorComputed;
      document.querySelector(".colorHouse").style = this.colorComputed;
    }
  },
  methods: {
    ...mapActions({
      goToView: "virtualrow/goToView",
    }),
    timerFunction() {
      const self = this;
      this.timeout = setInterval(function() {
        if (self.percent <= 0) {
          clearInterval(self.timeout);
        }
        if (self.view === "Turn" && self.restart) {
          clearInterval(self.timeout);
          self.timerFunction();
        }
        self.seconds -= 1;
      }, 1000);
    },
    toHome() {
      this.goToView({ view: "QR" });
    },
  }
};
</script>

<style lang="scss">
$borderWidth: 5px;
$animationTime: 7s;
$border-color-default: rgb(223, 230, 237);
$border-color-progressbar: #50a5eb;
$size: 52px;
$howManySteps: 100; //this needs to be even.

.progress {
  width: $size !important;
  height: $size !important;
  line-height: $size;
  background: none;
  margin: 1px auto;
  box-shadow: none;
  position: relative;
  position: fixed;
  top: 7px;
  left: 7px;
  background-color: #ffffff !important;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress-left {
    left: 0;
  }
  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-progressbar;
  }
  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress-right {
    right: 0;
    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }
  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.colorHouse {
  fill: #00599d;
  color: #00599d;
}

.colorHouseDanger {
  fill: rgb(240, 64, 64);
  color: rgb(240, 64, 64);
}

/* This for loop creates the necessary css animation names
Due to the split circle of progress-left and progress right, we must use the animations on each side.
*/
@for $i from 1 through $howManySteps {
  $stepName: ($i * (100 / $howManySteps));

  //animation only the left side if below 50%
  @if $i <= ($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{$i} $animationTime linear forwards;
      }
      .progress-left .progress-bar {
        animation: 0;
      }
    }
  }
  //animation only the right side if above 50%
  @if $i > ($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        // eslint-disable-next-line prettier/prettier
        animation: loading-#{($howManySteps/2)}$animationTime
          linear
          forwards; //set the animation to longest animation
      }
      .progress-left .progress-bar {
        animation: loading-#{$i -
          ($howManySteps/2)}
          $animationTime
          linear
          forwards
          $animationTime;
      }
    }
  }
}

//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (180/ ($howManySteps/2));
  $degrees: ($degrees * $i);
  @keyframes loading-#{$i} {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate($degrees);
      transform: rotate(#{$degrees}deg);
    }
  }
}
</style>
