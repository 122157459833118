<template lang="pug">
  .Container(
    :class="!!!turn ? 'container-logo pb-1' : 'dk-padd pb-1'"
    :style="isAtril ? 'color: #545454; height: calc(100vh - 176px); display: flex; flex-direction: column; justify-content: center;' : 'display: flex; flex-direction: column; justify-content: center; font-size: 1.3rem; font-weight: bold; height: calc(100vh - 102.9px);'"
  )
    .Content
      audio#attention(@ended="soundEmmited")
        source(src="@/assets/sounds/campanitas.mp3" type="audio/mpeg")
      audio#aproximate
        source(src="@/assets/sounds/turno_audio.mp3" type="audio/mpeg")
      div(
        v-if="stage === -1"
        style="position: absolute; margin-top: 30vh;"
      )
        span(style="text-align: center")
          button.btn.btn-info(
            @click="startTurn"
            :style="isAtril ? 'font-size: 1.5rem; width: 100%; display: inline-block;' : 'width: 100%; display: inline-block;'"
          ) Reintentar
        h5.mt-3(
          :style="isAtril ? 'font-size: 2rem;text-align: center; color: firebrick;' : 'text-align: center; color: firebrick;'"
        ) No fue posible establecer un turno. Por favor reinténtelo o comuníquese con el soporte técnico.

      .position-relative.d-inline.text-center(v-if="stage === 0")
        img(:src="Conected" class="size_icon mx-auto")
        slot(name="enqueue")
        h5(
          :style="isAtril ? 'font-size: 2rem; font-weight: bold !important; color: #545454;' : ' font-weight: bold !important; color: #545454;'"
        ) Estamos solicitando tu turno. Por favor espera.

      div(v-if="stage === 1" :class="isAtril && 'mt-5'")
        slot(name="waiting" v-bind:turn="turnCommon")
        template(v-if="!onlyGenerate")
          .mt-3
            b-form-checkbox.audioNotification(
              :class="{'isDisabled': !audioNotification, 'isEnabled': audioNotification}"
              id="checkbox-1"
              v-model="audioNotification"
              name="checkbox-1"
            ) Notificarme con audio cuando sea atendido
          p.mt-2(v-if="!hideVideo") Mientras esperas:
          div(v-if="!hideVideo")
            video(
              style="max-width: 350px; margin: auto; display: block;"
              id="video"
              :src="videoSource"
              :poster="videoPoster"
              playsinline
              controls
            )
            p.mt-2 Conoce nuestro Autoservicio Digital
          div(class="div_center_button" :style="hideVideo ? `margin-top: 2rem;` : ``")
            .mb-4.color-colmedica-light(
              v-if="status === 'ABSENT'"
            ) El turno fue marcado como 'Ausente'. Debe dirigirse a un asesor para que puedan volver a llamarlo.
            u(@click="showModal = true" class="modal_button cancelTurnDialog color-colmedica-light" style="cursor: pointer;").pb-5 Cancelar Solicitud
        b-modal(v-model="showModal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
          div(class="p-2 position-relative w-auto h-auto")
            div(class="d-block text-center modal_inside" )
              p(class="my-4 modal_highlight_text") Estás a punto de cancelar la solicitud
              p(class="my-4") Esto cancelará el proceso de atención con el asesor {{ comp }}
              p(class="my-4") ¿Qué deseas hacer?
            div(class="position-relative")
              b-button(@click="cancelTurnAction()" variant="outline-danger" class="btn-block cancelTurnConfirmation") Cancelar solicitud
              b-button(@click="showModal = false" variant="bluecolmedica" :style="colorComputed" class="btn-block") Continuar con el asesor {{ comp }}
      
      div(v-if="stage === 2")
        img(:src="Altavoz" alt="altavoz" v-if="!siteIsOffice" style="max-width: 66px; margin: 25px 0;")
        img(src="../../assets/bell.png" alt="bell" v-else)
        h5.mt-4.text-middle.color-colmedica-gray(
          :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : 'font-weight: bold !important; color: #545454;'"
        ) Tu número
        h4.text-middle.color-colmedica-light.font-weight-bold(
          :style="isAtril ? 'font-size: 2rem; !important; font-weight: bold;' : ' font-weight: bold !important; margin-bottom: 0;'"
        ) {{ myTurn }}
        h5.mt-4.text-middle.color-colmedica-gray(
          :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
        ) Ha sido llamado
        //- ********** Modificacion temporal mientras se logra obtener el nombre del puesto de api mobile
        template(v-if="attentionModule")
          h5.mt-4.text-middle.color-colmedica-gray.font-weight-bold(
            :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;': 'font-weight: bold !important; color: rgb(84, 84, 84); max-width: 187px; margin: 15px auto; font-size: 1.3rem; line-height: 25px;'"
          ) Por favor acércate al módulo de atención
          h4.text-middle.color-colmedica-light.font-weight-bold {{ attentionModule }}
          h5.mt-4.text-middle.color-colmedica-light(
            style="font-weight: bold !important; color: #545454;"
          ) Serás atendido por
            h4.ml-2.color-colmedica-light.mt-1
              strong {{ attentionAgent }}
        template(v-else)
          h5.mt-4.text-middle(
            :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;': ' font-weight: bold !important; color: #545454;'"
          ) Por favor procede al módulo que se indica en la pantalla de TV

      div(v-if="stage === 3")
        h5.mt-4.text-middle.color-colmedica-gray(
          :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
        ) Tu turno ha finalizado.
        h5.mt-4.text-middle.color-colmedica-gray(
          :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
        ) Te esperamos en una próxima ocasión.
        slot(name="endInfo")
          h6.mt-4.text-middle.color-colmedica-gray(
            :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
          ) Ya puedes cerrar esta ventana
    .buttons.Footer(:style="colorSecondaryComputed" v-if="stage === 1")
      .d-flex.justify-content-end
        slot(name="waitingActions")
    .Footer(v-if="stage !== 1 || isAtril")
</template>

<script>
import { mapActions, mapState } from "vuex";
import LogoColmedica from "@/assets/colmedica_logo.png";
import Conected from "@/assets/conected.gif";
import Altavoz from "@/assets/altavoz.png";

export default {
  name: "Turno",

  data: () => ({
    stage: 0,
    LogoColmedica,
    Conected,
    Altavoz,
    errorCounter: 0,
    audioNotification: false,
    showModal: false,
    timerSound: null,
    soundEnded: false
  }),

  created() {
    this.startTurn();

    setTimeout(() => {
      let origin = 'fv_';
      if (location.hash.includes("fv_atril")) origin = 'fv_atril_';
      // tracking
      let trackingData  = {
        uuid: window.person.id,
        accion: 'customer-turno-asignado',
        debmedia_turn_code: this.turn.code,
        url_origen: window.location.href,
        origen: origin + this.currentSite?.type,
        mensajes: ''
      }
      
      this.sendTracking(trackingData);
    }, 1000);

  },
  
  props: {
    hideVideo: {
      type: Boolean,
      default: false
    },
    waitVideoSource: {
      type: String
    },
    waitVideoPoster: {
      type: String
    },
    onlyGenerate: {
      type: Boolean,
      default: false
    },
    tramiteName: {
      type: String,
      required: true
    },
    coorelation_key: {
      type: String
    },
    userInfo: {
      required: true
    },
    branchId: {
      type: String
    },
    queueId: {
      type: String
    },
    source: {
      type: String,
      required: true
    },
    withAnalitycExtraFields: {
      type: Boolean,
      default: false
    },
    withVideocall: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    async actualTurn(val) {
      // if (!this.audioNotification && val != "canceled") {
      document.getElementById("aproximate").currentTime = 0;
      await document.getElementById("aproximate").play();
      // }
    },

    stage(val) {
      if (val === 3) this.$emit("endTurn");
    },

    status(v) {
      this.turnPosition();
      
      // tracking
      setTimeout(() => {
        let origin = 'fv_';
        if (location.hash.includes("fv_atril")) origin = 'fv_atril_';
        let trackingData  = {
          uuid: window.person.id,
          accion: 'customer-cambio-status: ' + v,
          debmedia_turn_code: this.turn.code,
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ''
        }
        
        this.sendTracking(trackingData);
      }, 1000);

    }
  },

  computed: {
    ...mapState({
      turn: state => state.turns.info,
      isAtril: state => state.virtualrow.isAtril,
      env: state => state.env,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      currentSite: state => state.virtualrow.currentSite,
    }),

    comp() {
      const comp = this.env.VUE_APP_TEXT_COMPANY || this.env.VUE_APP_COMPANY;
      return comp.charAt(0).toUpperCase() + comp.slice(1);
    },

    videoSource() {
      return (
        this.waitVideoSource ||
        "https://bsix.assets.gobrilliant.com/colmedica/Colmedica_Promo_Video.mp4"
      );
    },

    videoPoster() {
      return (
        this.waitVideoPoster ||
        "https://bsix.assets.gobrilliant.com/colmedica/VideoColmedicaPoster.jpg"
      );
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    infoOfTurn() {
      return {
        onlyGenerate: this.onlyGenerate,
        source: this.source,
        queueId:
          this.demoMode || !this.queueId
            ? this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE
            : this.queueId,
        branchId:
          this.demoMode || !this.branchId
            ? this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH
            : this.branchId,
        coorelation_key: this.coorelation_key,
        withVideocall: this.withVideocall,
        withAnalitycExtraFields: this.withAnalitycExtraFields,
        params: this.userInfo
      };
    },

    turnCommon() {
      return {
        myTurn: this.myTurn,
        actualTurn: this.actualTurn,
        tramiteName: this.tramiteName
      };
    },

    status() {
      return this.turn?.status;
    },

    actualTurn() {
      return this.turn?.jsonDetails?.actualTurn;
    },

    myTurn() {
      return this.turn?.jsonDetails?.turn;
    },

    attentionModule() {
      return this.turn.worker?.name;
    },

    attentionAgent() {
      return `${this.turn.worker?.actualUser?.uUser?.firstName || ""} ${this
        .turn.worker?.actualUser?.uUser?.lastName || ""}`.trim();
    }
  },

  methods: {
    ...mapActions({
      getTurn: "turns/getTurn",
      cancelTurnAction: "turns/cancelTurnAction",
      sendTracking: "virtualrow/handleTracking"
    }),
    cancelTurnHandler() {
      this.$emit("goBack");
    },
    async startTurn() {
      this.stage = 0;
      if (this.errorCounter > 3) this.errorCounter = 0;
      return this.getTurn(this.infoOfTurn)
        .then(turnInfo => {
          if (this.onlyGenerate)
            this.$emit("generatedTurn", {
              turnInfo,
              generationInfo: this.infoOfTurn
            });
        })
        .catch(error => {
          this.errorCounter++;
          console.error("Cant get turn for turn component", error);
          if (this.errorCounter < 3) setTimeout(() => this.startTurn(), 3000);
          else this.$emit("errorRequestTurn");
        });
    },

    async turnAudioPlay() {
      if (this.audioNotification) {
        if (document.getElementById("attention")) {
          try {
            this.timerSound = setTimeout(() => {
              try {
                if (
                  document.getElementById("attention") &&
                  document.getElementById("attention").currentTime > 0
                )
                  return;
              } catch (error) {
                console.error("Invalid turn audio state", error);
              }
              this.soundEmmited();
            }, 2000);
            document.getElementById("attention").currentTime = 0;
            await document.getElementById("attention").play();
          } catch (error) {
            console.error("Sound of meeting cant play in turn component");
            this.soundEmmited();
          }
        } else {
          console.error("Sound tag not found", this.$refs);
          this.soundEmmited();
        }
      } else this.soundEmmited();
    },

    soundEmmited() {
      this.stage = 2;
    },

    turnPosition() {
      if (this.turn) {
        if (this.stage <= 0) {
          if (this.status === "WAITING_TO_BE_CALLED") this.stage = 1;
          else if (this.status === "ANNOUNCED") this.turnAudioPlay();
          else this.stage = 3;
        } else if (this.stage === 1) {
          if (this.status === "ANNOUNCED") this.turnAudioPlay();
          else if (this.status !== "WAITING_TO_BE_CALLED") this.stage = 3;
        } else if (this.stage === 2) {
          if (
            this.status === "WAITING_TO_BE_CALLED" ||
            this.status === "ABSENT"
          )
            this.stage = 1;
          else if (this.status !== "ANNOUNCED") this.stage = 3;
        }
      } else {
        if (this.stage > 0) this.stage = 3;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dk-padd {
  padding-top: 15px;
  text-align: center;
}

.size_icon {
  width: 173px;
}

#video {
  width: 100%;
}
</style>
