<template lang="pug">
.CurrentUser.Container(:style="isAtril ? 'color: #545454; max-width: 1012px; margin: auto; height: calc(100vh - 173px);' : 'color: #545454; font-size: 1.3rem;'")
  .Content
    div(style="text-align: center;").mt-3
      div(style="position: relative; height: 66px; margin-bottom: 27px;").mt-5
        span.text-center.mt-2.h1(:class="isAtril ? 'dk-pos-dk' : 'dk-font'" style="font-weight: bold !important;") {{ siteIsOffice ? "Oficina" : "Centro Médico" }} {{ siteName }}
      HelpIcon.gray(:size="isAtril ? 70 : 50" :style="isAtril ? 'margin-top: 36px;' : ''")
      div.text-center.dk-othre.d-flex.justify-content-center
        p.mt-3(:style="isAtril ? 'max-width: 670px;' : ''") Este documento no tiene un plan activo. Puedes continuar con estos datos, o digitar nuevos datos
    .big_box(:style="isAtril ? 'width: 400px; margin: 0 auto;' : 'width: 100%;'")
      .dk-overlay
        p(v-if="currentUser ? currentUser.Nombres : currentUser") {{ currentUser.Nombres }}
        p Documento: {{ currentUser ? currentUser.TipoIdentificacion : "" }} {{ currentUser ? currentUser.NumeroIdentificacion : "" }}
    b-button.mt-4(variant="bluecolmedica" @click="cleanAndBack" :style="isAtril ? 'font-size: 1.7rem; margin: 0 auto; display: block; width: 100%; max-width: 350px;' + colorComputed : 'width: 100%; max-width: 208px; font-size: 1.2rem !important; margin: 20px auto; display: block;' + colorComputed") Digitar nuevos datos
    b-button.mt-4(
      variant="bluecolmedica",
      @click="goUserView",
      :style="isAtril ? 'font-size: 1.7rem; margin: auto; display: block; width: 100%; max-width: 350px;' + colorComputed : 'font-size: 1.2rem !important; max-width: 208px; width: 100%; margin: auto; display: block; margin-bottom: 3rem; ' + colorComputed"
    )
      div(v-if="!!!charging")
        span
          | Continuar con estos datos
        //- span
        //-   Continue(size="24px")
      div(v-else)
        MiniSpinner
  .buttons.Footer(:style="colorSecondaryComputed").p-4
    .d-flex.justify-content-between
</template>

<script>
import { mapActions, mapState } from "vuex";

import HelpIcon from "mdi-vue/HelpCircle.vue";
import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold";
import MiniSpinner from "../components/MiniSpinner";

export default {
  watch: {
    currentUser(val) {
      if (!val) this.goBack();
    }
  },

  components: {
    Header,
    HelpIcon,
    Continue,
    MiniSpinner
  },

  created() {
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  computed: {
    ...mapState({
      currentUser: state => state.virtualrow.currentUser,
      charging: state => state.virtualrow.charging,
      currentSite: state => state.virtualrow.currentSite,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor
    }),
    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },
    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },
    siteIsOffice() {
      return this.currentSite?.type === "of";
    },
    siteName() {
      return this.currentSite?.name;
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrow/goToView",
      goBack: "virtualrow/goBack",
      cleanCurrentUser: "virtualrow/cleanCurrentUser"
    }),

    cleanAndBack() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.cleanCurrentUser();
      this.goBack();
    },

    goUserView() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.goToView({ view: "NoDate" });
    }
  }
};
</script>

<style scoped>
.CurrentUser {
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
  margin: auto;
}

.CurrentUser > div {
  display: table-cell;
  vertical-align: middle;
}

.gray {
  color: gray;
}

.big_box {
  position: relative;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.dk-font {
  font-size: 1.3rem;
}

.dk-pos-dk {
  position: absolute;
  top: -45%;
  left: 0px;
  transition: 1s;
  right: 0px;
  font-size: 2.8rem;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>
